import React, { useState } from "react";
import "./ReportMachine.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import AreaChart from "../Common/Charts/AreaChart1/AreaChart";
import moment from "moment";
import { GetDeviceHourlyReportData } from "../../Service/DeviceAPI";
import { toast } from "react-toastify";

const ReportMachine = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const date = location?.state?.date;
  const roomName = location?.state?.roomName;
  let array = location?.state?.filterDate;
  // const arr = array[0]
  const groupedData = array[0].reduce((acc, curr) => {
    const updateDate = new Date(curr.updatedAt);
    updateDate.setHours(updateDate.getHours() + 5);

    const date = updateDate.toISOString().split("T")[0];
    if (!acc[date]) acc[date] = [];
    acc[date].push(curr);
    return acc;
  }, {});

  const arr = Object.keys(groupedData).map((date, i) => {
    const dayData = groupedData[date].sort(
      (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
    );
    const firstTotalKWH = dayData[0].total;
    const total = dayData[dayData.length - 1].total;
    // const count = dayData.length;
    const DateData = new Date(dayData[i].updatedAt).getDate();
    const todayDate = new Date().getDate();
    const todayHours = new Date().getHours();

    const count = DateData === todayDate ? todayHours : 24;

    const machineId = dayData[0]?.machineId;
    const dailyTotal = total - firstTotalKWH;
    return {
      updatedAt: date,
      firstTotalKWH,
      total,
      count,
      machineId,
      dailyTotal,
      title: dayData[0].title,
      flow: (total - firstTotalKWH) / count,
    };
  });

  let arrLen = arr.length;
  let totalT = 0;
  for (let i = 0; i < arr.length; i += 1) {
    totalT += parseInt(arr[i]?.dailyTotal);
  }
  // let totalT = arr[0]?.total - arr[arr.length - 1]?.total;
  let totalAvg = totalT / arrLen;
  let linetemp = 0;
  let addlinetemp = arr?.map((data) => {
    return parseFloat(data?.linetemp);
  });
  for (var i in addlinetemp) {
    linetemp += addlinetemp[i];
  }
  let linetempavg = linetemp / arrLen;

  const reportformat =
    "KWSB \t" + roomName + "\t" + date.startDate + "\t\t" + date.endDate;
  const pdfbtn = () => {
    var doc = new jsPDF("l", "pt");
    var content = document.getElementById("reportid");
    doc.html(content, {
      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(12);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          // doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
          doc.text(
            "Page" + String(i) + " of " + String(pageCount),
            842 - 30,
            595 - 30,
            null,
            null,
            "right"
          );
        }
        doc.save(reportformat);
      },
    });
  };
  const close = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="reportmain">
        <div className="headleft">
          <BsArrowLeftShort
            className="icon cursor"
            onClick={() => navigate(-1)}
          />
          <div className="heading">Daily Report</div>
        </div>
        <div className="report" id="reportid">
          <div className="date">
            {date.startDate} ~ {date.endDate}
          </div>
          <div className="reportsMainContainer">
            <table className="tablemain" id="tablee">
              <thead className="tablehead">
                <tr>
                  {arr[0]?.updatedAt && (
                    <th className="tableh" rowSpan="2">
                      Date
                    </th>
                  )}
                  <th className="tableh" colSpan="5">
                    {arr[0]?.title}
                  </th>
                </tr>
                <tr className="tablerow">
                  {arr[0]?.total && <th className="tableh">Flow M3/H</th>}
                  {arr[0]?.total && <th className="tableh">Total M3</th>}
                </tr>
              </thead>
              <tbody className="tablebody">
                {arr?.map((data, i, newArray) => {
                  let previousTotal = i > 0 ? newArray[i - 1]?.total : 0;
                  let difference = data?.total - previousTotal;
                  return (
                    <>
                      <tr className="tablerow" key={i}>
                        {data?.updatedAt && (
                          <td
                            className="tabled datelink"
                            onClick={async () => {
                              // let filterHour = array?.map((meter) => {
                              //   return {
                              //     ...meter,
                              //     HourReadings: meter?.HourReadings.filter(
                              //       (val) => {
                              //         let dateSearch = val?.date;
                              //         if (dateSearch === ddd?.date) {
                              //           return true;
                              //         } else {
                              //           return false;
                              //         }
                              //       }
                              //     ),
                              //   };
                              // });
                              // navigate(`/dev/reportHour`, {
                              //   state: {
                              //     filterDateHour: filterHour,
                              //     date: ddd?.date,
                              //     roomName: roomName,
                              //   },
                              // });
                              setLoading(true);
                              let payload = {
                                machineId: data?.machineId,
                                date: data?.updatedAt,
                              };
                              let res = await GetDeviceHourlyReportData(
                                payload
                              );
                              if (res?.error != null) {
                                setLoading(false);
                                toast.error(res.error);
                              } else {
                                // setDeviceData(res?.data?.result);
                                setLoading(false);
                                navigate("/dev/reportHour", {
                                  state: {
                                    date: moment(data?.updatedAt).format(
                                      "DD-MM-YYYY"
                                    ),
                                    filterDateHour: res?.data?.result,
                                  },
                                });
                              }
                            }}
                          >
                            {moment(data?.updatedAt).format("DD-MM-YY")}
                          </td>
                        )}
                        {data?.total && (
                          <td className="tabled">
                            {/* {parseInt(difference).toLocaleString()} */}
                            {parseInt(data?.flow)}
                          </td>
                        )}

                        {data?.total && (
                          <td className="tabled">
                            {parseInt(data?.dailyTotal).toLocaleString()}{" "}
                          </td>
                        )}
                      </tr>
                    </>
                  );
                })}
                <tr className="tablerow">
                  <th className="tableh tablehead" colSpan={2}>
                    Total
                  </th>
                  {/* {arr[0]?.total && (
                    <td className="tableh tablehead" >
                      {totalAvg}
                    </td>
                  )} */}
                  {/* {parseInt((arr[arr?.length - 1]?.total - arr[0]?.total) / arr?.length).toLocaleString()} */}
                  {arr[0]?.total && (
                    <td className="tableh tablehead">
                      {totalT}
                      {/* {parseInt(
                        arr[arr?.length - 1]?.total - arr[0]?.total).toLocaleString()} */}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* {arr?.map((data, i) => { */}
        {/* return ( */}
        <div className="areaChartSteamFlow" key={i}>
          <AreaChart
            labels={arr?.map((time) =>
              moment(time?.updatedAt).format("DD-MM-YY")
            )}
            flow={arr?.map((data) => parseInt(data?.dailyTotal))}
            flowlabel={arr[0]?.title}
            borderColor={"rgb(22,117,224)"}
            backGroundColor={"rgba(22,117,224,0.5)"}
          />
        </div>
        {/* ); */}
        {/* })} */}
        <div className="btnss">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbtn"
            table="tablee"
            filename={reportformat}
            sheet="tablexls"
            buttonText="Excel"
          />
          <button className="pdfbtn" onClick={pdfbtn}>
            Pdf
          </button>
          <button className="pdfbtn" onClick={close}>
            Close
          </button>
        </div>
      </div>
    </>
  );
};
export default ReportMachine;
