import React from "react";
import "./ReportMachineHour.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment";
import AreaChart from "../Common/Charts/AreaChart1/AreaChart";

const ReportMachine = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const date = location.state.date;
  const roomName = location.state.roomName;
  let array = location?.state?.filterDateHour;
  const arr = array[0];
  // let ar = array[0];
  // let arr = [].concat(ar).reverse();

  // Get Total Values
  let arrLen = arr.length;
  let totalT = arr[0]?.total - arr[arr.length - 1]?.total;
  let totalAvg = totalT / arrLen;
  let linetemp = 0;
  let addlinetemp = arr?.map((data) => {
    return parseFloat(data?.linetemp);
  });
  for (var i in addlinetemp) {
    linetemp += addlinetemp[i];
  }
  let linetempavg = linetemp / arrLen;

  const reportformat =
    "Tracking World\t" +
    roomName +
    "\t" +
    date.startDate +
    "\t\t" +
    date.endDate;
  const pdfbtn = () => {
    var doc = new jsPDF("l", "pt");
    var content = document.getElementById("reportid");
    doc.html(content, {
      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(12);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          // doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
          doc.text(
            "Page" + String(i) + " of " + String(pageCount),
            842 - 30,
            595 - 30,
            null,
            null,
            "right"
          );
        }
        doc.save(reportformat);
      },
    });
  };
  const close = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="reportmain">
        <div className="headleft">
          <BsArrowLeftShort
            className="icon cursor"
            onClick={() => navigate(-1)}
          />
          <div className="heading">Hourly Report</div>
        </div>
        <div className="report" id="reportid">
          <div className="date">{date}</div>
          {/* <div className="date">{arr[arr?.length - 1]?.updatedAt}</div> */}
          <div className="reportsMainContainer">
            {array?.map((data, index) => {
              const DateData = new Date(data[index].updatedAt).getDate();
              const todayDate = new Date().getDate();
              const todayHours = new Date().getHours();

              const count = DateData === todayDate ? todayHours : 24;
              return (
                <>
                  <table className="tablemain" id="tablee" key={index}>
                    <thead className="tablehead">
                      <tr>
                        {data?.[data?.length - 1] &&
                          data?.[data?.length - 1]?.updatedAt && (
                            <th className="tableh" rowSpan="2">
                              Time | Date
                            </th>
                          )}
                        <th className="tableh" colSpan="3">
                          {data[0]?.title}
                        </th>
                      </tr>
                      <tr className="tablerow">
                        {/* <th className="tableh">Time | Date</th> */}
                        {data?.[data?.length - 1]?.flow && (
                          <th className="tableh">
                            Flow{" "}
                            {data?.flow && data?.title == "Steam"
                              ? " KG/H"
                              : data?.title == "Power"
                              ? " KW"
                              : data?.title == "Production"
                              ? " m/m"
                              : " M3/H"}
                          </th>
                        )}
                        {data?.[data?.length - 1]?.total && (
                          <th className="tableh">
                            Total{" "}
                            {data?.title == "Steam"
                              ? " Ton"
                              : data?.title == "Power"
                              ? " KW"
                              : data?.title == "Production"
                              ? " m/m"
                              : " M3"}
                          </th>
                        )}
                        {data?.[data?.length - 1]?.linetemp && (
                          <th className="tableh">Line Temp °C</th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="tablebody">
                      {data?.map((ddd, i, array) => {
                        let previousTotal = i > 0 ? array[i - 1]?.total : 0;
                        let difference = ddd.total - previousTotal;
                        return (
                          <tr className="tablerow" key={i}>
                            <>
                              {index === 0 && (
                                <td className="tabled">
                                  {moment(ddd?.updatedAt).format(
                                    "hh:mm | DD-MM-YY"
                                  )}
                                </td>
                              )}
                              {ddd?.total && (
                                <td className="tabled">{ddd?.flow}</td>
                              )}
                              {data?.[data?.length - 1]?.total && (
                                <td className="tabled">
                                  {parseInt(ddd?.total).toLocaleString()}{" "}
                                </td>
                              )}
                              {data?.[data?.length - 1]?.linetemp && (
                                <td className="tabled">
                                  {parseInt(ddd?.linetemp).toLocaleString()}{" "}
                                </td>
                              )}
                            </>
                          </tr>
                        );
                      })}

                      <tr className="tablerow">
                        {data?.[data?.length - 1] && (
                          <th className="tableh tablehead">Avg | Total</th>
                        )}
                        {data?.[data?.length - 1]?.total && (
                          <td className="tableh tablehead">
                            {parseInt(
                              (data[data?.length - 1]?.total - data[0]?.total) /
                                count
                            ).toLocaleString()}
                          </td>
                        )}
                        {data?.[data?.length - 1]?.total && (
                          <td className="tableh tablehead">
                            {parseInt(
                              data[data?.length - 1]?.total - data[0]?.total
                            ).toLocaleString()}
                          </td>
                        )}
                        {data?.[data?.length - 1]?.linetemp && (
                          <td className="tableh tablehead">
                            {/* {parseInt(linetempavg).toLocaleString()} */}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </>
              );
            })}
          </div>
        </div>

        <div className="btnss">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbtn"
            table="tablee"
            filename={reportformat}
            sheet="tablexls"
            buttonText="Excel"
          />
          <button className="pdfbtn" onClick={pdfbtn}>
            Pdf
          </button>
          <button className="pdfbtn" onClick={close}>
            Close
          </button>
        </div>
        {array?.map((data, i) => {
          return (
            <div className="areaChartSteamFlow" key={i}>
              <AreaChart
                labels={data?.map((time) =>
                  moment(time?.updatedAt).format("HH:mm")
                )}
                flow={data?.map((data) => parseInt(data?.flow))}
                flowlabel={data[0]?.title}
                borderColor={
                  data?.title === "Steam"
                    ? "rgb(255,165,0)"
                    : data?.title === "Energy"
                    ? "rgb(115, 147, 179)"
                    : data?.title === "Production"
                    ? "rgb(204, 153, 0)"
                    : "rgb(22,117,224)"
                }
                backGroundColor={
                  data?.title === "Steam"
                    ? "rgba(255,165,0,0.5)"
                    : data?.title === "Energy"
                    ? "rgba(115, 147, 179,0.5)"
                    : data?.title === "Production"
                    ? "rgba(204, 153, 0,0.5)"
                    : "rgba(22,117,224,0.5)"
                }
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
export default ReportMachine;
