import React, { useEffect, useState } from "react";

// API
import { GetDeviceData, DeleteDeviceAPI, GetDeviceHourlyReportData, GetDeviceHourReportData } from "../../Service/DeviceAPI";
import { useLocation, useNavigate } from "react-router-dom";

// Helper
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiInformationLine } from "react-icons/ri";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

// Styling
import "./Device.scss";
// Asserts
import NoImage from "../../Asserts/Imgs/noImg.png";

// Other Components
import { useSelector } from "react-redux";
import { Button, Spin } from "antd";
import ROLES from "../../Utils/Roles";
import ConfirmationModel from "../Common/ConfirmationModel/ConfirmationModel";
import ImgURL from "../../Utils/ImgUrlGen";
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment";
import AreaChart from "../Common/Charts/AreaChart1/AreaChart";
import { DateRangePicker } from "rsuite";
import { GetDevices } from "../../Service/DeviceAPI";
import { GetDeviceDailyReportData } from "../../Service/DeviceAPI";

// ------------------------------------------Start Function------------------------

export default function Device() {
  // Searching the data from start to end date------------
  const [value, setValue] = useState([]);

  let new1 = moment(value[0])?.format("DD-MM-YYYY");
  let new2 = moment(value[1])?.format("DD-MM-YYYY");

  let sdate = moment(value[0])?.format("YYYY-MM-DD");
  let edate = moment(value[1])?.format("YYYY-MM-DD");

  let sendDate = {
    startDate: new1,
    endDate: new2,
  };
  let dateback = {
    startDate: sdate,
    endDate: edate,
  };
  const settingDate = (event) => {
    if (event == null) {
      setValue([]);
    } else {
      setValue(event);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [refreshPage, setRefreshPage] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState([])
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    deviceId: null,
    loading: false,
  });

  const machine = location?.state?.machine;
  const buildingId = location?.state?.buildingId;
  const departmentId = location?.state?.departmentId;

  let machineId = machine?._id;

  const gettingDevices = async () => {
    setLoading(true)
    let payload = {
      machineId: machineId,
    };
    let res = await GetDevices(payload);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      setDevices(res?.data?.result);
    }
    setLoading(false)
  };

  const gettingDeviceData = async () => {
    let payload = {
      machineId: machineId,
    };
    let res = await GetDeviceData(payload);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      setDeviceData(res?.data?.result);
    }
  };
  useEffect(() => {
    gettingDeviceData();
    gettingDevices()
  }, [refreshPage]);

  const handleEditDevice = (device) => {
    navigate("/device/add", {
      state: {
        device: device,
        machineId: machineId,
        buildingId: buildingId,
        departmentId: departmentId,
      },
    });
  };

  // Getting User Data
  const userData = useSelector((state) => state?.userData);

  const handleAddDevice = () => {
    navigate("/device/add", {
      state: {
        machineId: machineId,
        buildingId: buildingId,
        departmentId: departmentId,
      },
    });
  };

  const handleDeleteDeviceConfirmation = (device) => {

    setDeleteConfirmation({
      open: true,
      deviceId: device?._id,
      loading: false,
    });
  };

  //   Delete Device Handle
  const handleDeleteDevice = async (device) => {
    setDeleteConfirmation({
      ...deleteConfirmation,
      loading: true,
    });
    const res = await DeleteDeviceAPI(deleteConfirmation?.deviceId);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      toast.success(res?.data?.message);
      setRefreshPage(!refreshPage);
    }
    setDeleteConfirmation({
      open: false,
      deviceId: null,
      loading: false,
    });
  };
  const handleNotDeleteDevice = () => {
    setDeleteConfirmation({
      open: false,
      deviceId: null,
      loading: false,
    });
  };

  // the function is use to find the date between start date and last date
  const findByDate = async () => {
    setLoading(true)
    let payload = {
      machineId: machineId,
      startDate: dateback?.startDate,
      endDate: dateback?.endDate,
    };
    let res = await GetDeviceDailyReportData(payload);
    // let res = await GetDeviceHourReportData(payload);
    if (res?.error != null) {
      setLoading(false)
      toast.error(res.error);
    } else {
      navigate("/dev/report", {
        state: { date: sendDate, filterDate: res?.data?.result },
      });
    }
    setLoading(false)
  };

  return (
    <>
        {
      loading && 
      <>
      <Spin size="large" fullscreen={true} />
      </>
    }
      <div className="MainDeviceWithFooter">
        <div className="deviceMainContainer">
          <div className="deviceMain">
            <div className="deviceHeader">
              <div className="headleft">
                <BsArrowLeftShort
                  className="icon cursor"
                  onClick={() => navigate(-1)}
                />
                <div className="heading">{machine?.title}</div>
              </div>
              <div className="datepiker">
                <DateRangePicker
                  showOneCalendar
                  className="rangepiker"
                  onChange={(event) => settingDate(event)}
                  value={value}
                  placeholder="Start Date ~ End Date"
                  renderValue={(value) => {
                    return (
                      moment(value[0])?.format("DD-MM-YYYY") +
                      " ~ " +
                      moment(value[1])?.format("DD-MM-YYYY")
                    );
                  }}
                />
                <Button
                  className="btnreport EditBtn"
                  type="submit"
                  onClick={findByDate}
                >
                  {" "}
                  Report
                </Button>
              </div>
              {([ROLES.Admin, ROLES.SuperAdmin].includes(userData?.role) && devices.length < 1) && (
                <Button className="EditBtn" onClick={handleAddDevice}>
                  Add Device
                </Button>
              )}
            </div>
            {devices && (
              <>
                <div className="tableMain">
                  <table>
                    <thead>
                      <tr>
                        <th>Particulars</th>
                        {devices?.map((data, i) => {
                          return (
                            <th key={i}>
                              <div className="titleMain">
                                <div className="title">
                                  {data?.title} <br />
                                  {data?.Device_ID}
                                </div>

                                {[ROLES.Admin, ROLES.SuperAdmin].includes(
                                  userData?.role
                                ) && (
                                    <span className="editDelete">
                                      <Button
                                        className="EditBtn"
                                        onClick={() => handleEditDevice(data)}
                                      >
                                        <AiFillEdit size={24} />
                                      </Button>
                                      <Button
                                        className="DelBtn"
                                        onClick={() =>
                                          handleDeleteDeviceConfirmation(data)
                                        }
                                      >
                                        <AiFillDelete size={24} />
                                      </Button>
                                    </span>
                                  )}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Flow</th>
                        {devices?.map((data, i) => {
                          return (
                            // data?.meterReadings?.[0]?.flow || data?.meterReadings?.[0]?.power && (
                            <td key={i}>
                              {data?.flow &&
                                `${data?.flow}`}   M3/H
                            </td>
                            // )
                          );
                        })}
                      </tr>
                      <tr>
                        <th>Total</th>
                        {devices?.map((data, i) => {
                          return (
                            <td key={i}>
                              {data?.total &&
                                parseInt(
                                  data?.total
                                ).toLocaleString()} M3
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>Update</th>
                        {devices?.map((data, i) => {
                          return (
                            <td key={i}>
                              {moment(
                                data?.updatedAt && data?.updatedAt
                              ).format("DD-MM-YYYY |HH:mm")}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}

            {deviceData?.map((data, i) => {
              return (
                <div className="areaChartSteamFlow" key={i}>
                  <AreaChart
                    labels={data?.map((time) =>
                      moment(time?.updatedAt).format("DD |HH:mm")
                    )}
                    flow={data?.map((data) =>
                      parseInt(data?.flow)
                    )}
                    flowlabel={devices[0]?.title}
                    borderColor={
                      data?.title == "Steam"
                        ? "rgb(255,165,0)"
                        : data?.title == "Energy"
                          ? "rgb(115, 147, 179)"
                          : data?.title == "Production"
                            ? "rgb(204, 153, 0)"
                            : "rgb(22,117,224)"
                    }
                    backGroundColor={
                      data?.title == "Steam"
                        ? "rgba(255,165,0,0.5)"
                        : data?.title == "Energy"
                          ? "rgba(115, 147, 179,0.5)"
                          : data?.title == "Production"
                            ? "rgba(204, 153, 0,0.5)"
                            : "rgba(22,117,224,0.7)"
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <ConfirmationModel
        open={deleteConfirmation.open}
        onOk={handleDeleteDevice}
        onCancel={handleNotDeleteDevice}
        confirmLoading={deleteConfirmation.loading}
        test={deleteConfirmation?.deviceId}
      >
        <div className="deleteModel">
          <div className="titleBox">
            <RiInformationLine className="icon" />{" "}
            <div className="title">
              {" "}
              Are you sure you want to delete this Device?{" "}
            </div>
          </div>
        </div>
      </ConfirmationModel>
    </>
  );
}
